import { GA4EcommerceEvents } from "./eventTypesEnum";
import {
  AllowedEvents,
  ViewItemListEvent as EcommerceViewItemListEvent,
} from "./ecommerceTypes";
import { removeNullValues } from "../helper/removeNullValues";

// Google Analytics 4 Ecommerce events
export const useGA4EcommerceEvents = () => {
  const pushGA4EcommerceEvent = async (
    event: GA4EcommerceEvents,
    data: AllowedEvents,
  ) => {
    const eventData = JSON.parse(JSON.stringify(data));
    const cleanedData = removeNullValues(eventData);
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({ event, ...cleanedData });
  };

  return {
    GA4EcommerceEvents,
    pushGA4EcommerceEvent,
  };
};

export type ViewItemListEvent = EcommerceViewItemListEvent;
