export enum GA4EcommerceEvents {
  VIEW_ITEM_LIST = "view_item_list",
  SELECT_ITEM = "select_item",
  VIEW_ITEM = "view_item",
  ADD_TO_CART = "add_to_cart",
  REMOVE_FROM_CART = "remove_from_cart",
  VIEW_CART = "view_cart",
  VIEW_CART_MODAL = "view_cart_modal",
  BEGIN_CHECKOUT = "begin_checkout",
  ADD_SHIPPING_INFO = "add_shipping_info",
  ADD_PAYMENT_INFO = "add_payment_info",
  PURCHASE = "purchase",
}
